import mainjson from './main';
import { Level } from '../utils/LoginFunctions';
import { TestMailing,SendMailing } from '../utils/ApiFunctions';

function checkIf(data) {
    if (parseInt(Level()) <= 0) {
        return true;
    }
    else {
        return false;
    }
}

async function send(id){
    var r = confirm("Are you certain you want to send this email? Is the test correct? There is no way back!");
    if (r == true) {
        let result =  await SendMailing(id);
        if (result.error == 0) {
            alert("Email verzonden");
        }
        else {
            alert("Email niet verzonden");
        }
        return "refreshlist";
    }
}

async function test(id,data){
    
    let result =  await TestMailing(id);
    if (result.error == 0) {
        alert("Testemail verzonden");
    }
    else {
        alert("Testemail niet verzonden");
    }
    return "refreshlist";
}

function checkCanSend(data) {
    if (data.emailing_tested == 1 && data.emailing_state == 'pending') {
        return true;
    }
    else {
        return false;
    }
}


export default function GetData() {
        let data = {
        "apitype":"mailings",
        "id":"emailing_id",
        "subidname":"emailing_subject",
        "options":["edit","delete","insert"],
        "nameSingle":"mailing",
        "nameMultiple":"mailings",
        "extraoptions":[
            {
                "name":"Test",
                "method":test,
            },
            {
                "name":"Send",
                "method":send,
                "if":checkCanSend
                
            },
        ],
      
        "fields":{
            "field1":{
                "name":"Subject",
                "field":"emailing_subject",
                "type":"TextInput",
                "required":true,
                "list":true,
                "explain":"In the subject you can use the tags <firstname> and <lastname> to personalize it."
            },
            "field2":{
                "name":"Body",
                "field":"emailing_body",
                "type":"TextArea",
                "height":400,
                "required":true,
                "list":false,
                "explain":"In the body you can use the tags <firstname> and <lastname> to personalize it.\nYou can also insert the <image> tag to display the image uploaded with the mailing. It is always the full width of the email.\nAnd you can also use the <code> tag.\nThe default <code> tag will give a 100% discount for one ticket.\nIf you want to specify the discount you have these options:\n\ntype='percentage' (this can be percentage or amount)\nvalue='100' (this can be the percentage if type is percentage, or an amount in cents when it is amount)\nshows='15,16' (this is a comma seperated list for the shows the code is valid for, multiple shows are comma seperated)\ndates='12' (this is a comma seperated list for the dates the code is valid for, multiple dates are comma seperated)\napply_on='ticket' (this can be ticket or full order, that the order applies on)\n\nExample: <code type='percentage' value='100' shows='15,16' dates='12' apply_on='ticket'>\nThis gives 100% discount on a single ticker of show 15 or 16, but only on date 12\n<code type='amount' value='100' apply_on='order'>\nThis gives 1 euro discount on an any order\n"
            },
            "field3":{
                "name":"Type",
                "field":"emailing_member_type",
                "type":"DropDown",
                "options": "membertypes",
                "required":true,
                "list":true
            }, 
            "field4":{
                "name":"Test email address",
                "field":"emailing_test_email",
                "type":"TextInput",
                "required":true,
                "list":false
            },
            "field5":{
                "name":"Image",
                "field":"emailing_image",
                "type":"ImageUpload",
                "list":true,
                "resize":{
                    width:1024,
                    type:"fit",
                    quality:0.85,
                    bgcolor:"#ffffff"
                },
            },
            "field6":{
                "name":"Date",
                "field":"emailing_insert_date",
                "type":"DateTimeInput",
                "required":false,
                "list":true,
                "edit":false,
            },
            "field7":{
                "name":"State",
                "field":"emailing_state",
                "type":"Display",
                "required":false,
                "list":true,
                "edit":false,
            },
            "field100":{
                "name":"Client",
                "field":"emailing_client_id",
                "type":"DropDown",
                "options": "usergroups",
                "list":true,
                "if":checkIf
            }, 
            


        }
    }

    return data;
}