import React  from "react";
import Layout from "../components/layout";
import FormGenerator from "../components/form/FormGenerator";
import List from "../components/form/List";
import styled from "styled-components";
import Data from "../config/Mailings"
import Edit from "../components/form/Edit";


export default function Page() {
  return (
    <Layout>
      <Edit data={Data()}></Edit>
    </Layout>
  );
}